import { render, staticRenderFns } from "./escalationForm.vue?vue&type=template&id=25223e70&scoped=true"
import script from "./escalationForm.vue?vue&type=script&lang=js"
export * from "./escalationForm.vue?vue&type=script&lang=js"
import style0 from "./escalationForm.vue?vue&type=style&index=0&id=25223e70&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25223e70",
  null
  
)

export default component.exports
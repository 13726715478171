<template>
  <div class="escalation-form">
    <div class="scroll-box">
      <workOrderForm ref="work_order_form" mode="edit" @submit="submitOnHandle" />
    </div>
    <div class="form-btns">
      <van-button size="small" round type="info" native-type="button" :loading="submitLoading" style="width: 80%" @click="onclick">
        提交
      </van-button>
    </div>
  </div>
</template>

<script>
import { getDictOpsUrl, addOrderWorkUrl } from './api.js';
import { mapState } from 'vuex';
import { Button } from 'vant';
import workOrderForm from '../component/workOrderForm.vue';
import _ from 'lodash';

export default {
  name: 'escalationForm',
  components: {
    [Button.name]: Button,
    workOrderForm
  },
  props: {},
  model: {},
  data() {
    return {
      submitLoading: false
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId'])
  },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    onclick() {
      this.$refs.work_order_form.submit();
    },
    submitOnHandle(values, form, textForm) {
      this.submitLoading = true;
      form.userId = this.userId;
      Reflect.deleteProperty(form, 'photos');
      this.$axios
        .post(addOrderWorkUrl, form)
        .then(res => {
          if (res && res.code === 200) {
            this.$toast(res.msg);
            this.$router.go(-1);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
// constant(safe-area-inset-*)
// env(safe-area-inset-*)
// safe-area-inset-top
// safe-area-inset-right
// safe-area-inset-bottom
// safe-area-inset-left
.escalation-form {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding-bottom: calc(30px + constant(safe-area-inset-bottom));
  padding-bottom: calc(30px + env(safe-area-inset-bottom));
  background-color: #f8f8f8;
}
.scroll-box {
  box-sizing: border-box;
  width: 100%;
  max-height: calc(100% - 32px - 50px);
  padding: 20px 30px;
  overflow-x: hidden;
  overflow-y: auto;
}
.form-btns {
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  left: 0px;
  bottom: 30px;
  bottom: calc(30px + constant(safe-area-inset-bottom));
  bottom: calc(30px + env(safe-area-inset-bottom));
  display: flex;
  justify-content: center;
}
</style>

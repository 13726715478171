//登录
const loginUrl = `/gateway/hc-serve/miniapi/wechatInteractiveRecord/add`;

// 查询 - 字典表
const getDictOpsUrl = `/cyproxy/dict/getDictListByType`;

// 新增 - 事件
const addOrderWorkUrl = `/cyproxy/order-info/addInfo`;
//查询事件列表
const getOrderInfoListUrl = `/cyproxy/order-info/getOrderInfoList`;
//分页事件
const getOrderInfoPageListUrl = `/cyproxy/order-info/getOrderInfoPageList`;
export {
  loginUrl,
  getDictOpsUrl,
  addOrderWorkUrl,
  getOrderInfoListUrl,
  getOrderInfoPageListUrl,
};
